import { Api } from "../../../../config";
import * as ACTION_TYPES from "./ActionTypes";
import storage from "redux-persist/lib/storage";
import moment from "moment";
import { getAccessToken } from "../../../../api";

export function getMe() {
  return {
    type: ACTION_TYPES.GET_ME,
    request: {
      url: `${Api.baseURL}/profile`,
    },
  };
}

export function updateAccount(user, body, onSuccess, onError) {
  return (dispatch, getState) => {
    getAccessToken().then((accessToken) => {
      dispatch({ type: ACTION_TYPES.UPDATE_USER });
      const formData = new FormData();
      let isWorldWide = false;

      Object.keys(body).forEach((keyName) => {
        if (keyName === "isWorldWide") {
          if (body[keyName] === true) {
            formData.append("preferenceCountryId", null);
            formData.append("preferenceCityId", null);
            formData.append("range", null);
            isWorldWide = true;
          }
        }
      });

      Object.keys(body).forEach((keyName) => {
        if (
          body[keyName] !== null &&
          body[keyName] !== undefined &&
          body[keyName] !== ""
        ) {
          if (
            keyName === "preferenceCountryId" ||
            keyName === "preferenceCityId" ||
            keyName === "range"
          ) {
            if (isWorldWide === false) {
              formData.append(keyName, body[keyName]);
            }
          } else {
            if (keyName === "birthdate") {
              formData.append(
                "birthday",
                moment(body[keyName]).format("YYYY-MM-DD")
              );
            } else if (keyName === "avatar" || keyName === "thumbnailImage") {
              if (
                !body[keyName]?.includes("http://") &&
                !body[keyName]?.includes("https://")
              ) {
                formData.append(keyName, {
                  uri: body[keyName],
                  type: "image/jpeg",
                  name: `${keyName}.jpg`,
                });
              }
            } else if (Array.isArray(body[keyName])) {
              formData.append(keyName, JSON.stringify(body[keyName]));
            } else {
              formData.append(keyName, body[keyName]);
            }
          }
        }
      });

      formData.append("is_initialized", 1);
      const url = `${Api.baseURL}/profile/${user?.id}${
        body?.isRemoveAvatar === 1 || body?.isRemoveThumbnail === 1 ? "?" : ""
      }${body?.isRemoveAvatar === 1 ? "isRemoveAvatar=1&" : ""}${
        body?.isRemoveThumbnail === 1 ? "isRemoveThumbnail=1&" : ""
      }`;
      const request = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
        url: url,
        method: "put",
        body: formData,
      };
      fetch(request.url, request)
        .then((res) => {
          if (res.ok) {
            res.json().then((response) => {
              dispatch({
                type: ACTION_TYPES.UPDATE_USER_SUCCESS,
              });
              dispatch(getMe());
              // Toast.show("Successfully updated");
              if (onSuccess) {
                onSuccess(response);
              }
            });
          } else {
            res
              .json()
              .then((response) => {
                dispatch({
                  type: ACTION_TYPES.UPDATE_USER_FAILURE,
                  error: response.message,
                });
              })
              .catch((error) => {
                if (onError) {
                  onError(error);
                }

                console.error("catch error", error);
                dispatch({ type: ACTION_TYPES.UPDATE_USER_FAILURE });
              });
          }
        })
        .catch((error) => {
          if (onError) {
            onError(error);
          }
          dispatch({ type: ACTION_TYPES.UPDATE_USER_FAILURE });
          console.error("error", error);
        });
    });
  };
}

export function updatePassword(body, onSuccess, onError) {
  return (dispatch, getState) => {
    storage
      .getItem("auth")
      .then((authString) => {
        const auth = JSON.parse(authString);

        dispatch({ type: ACTION_TYPES.UPDATE_PASSWORD });
        const request = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: auth ? `Bearer ${auth.accessToken}` : "",
          },
          url: `${Api.baseURL}/change-password`,
          method: "put",
          body: JSON.stringify(body),
        };
        fetch(request.url, request).then((res) => {
          if (res.ok) {
            res.json().then((response) => {
              dispatch({
                type: ACTION_TYPES.UPDATE_PASSWORD_SUCCESS,
              });
              onSuccess(response);
            });
          } else {
            res
              .json()
              .then((response) => {
                dispatch({
                  type: ACTION_TYPES.UPDATE_PASSWORD_FAILURE,
                  error: response.message,
                });
                onError(response.message);
              })
              .catch((error) => {
                onError(error);
                console.error("catch error", error);
                dispatch({ type: ACTION_TYPES.UPDATE_PASSWORD_FAILURE });
              });
          }
        });
      })
      .catch((err) => {
        onError(err);
      });
  };
}

export function forgotPassword(payload) {
  return {
    type: ACTION_TYPES.FORGOT_PASSWORD,
    request: {
      url: `${Api.baseURL}/ForgotPassword`,
      method: "post",
      body: JSON.stringify(payload),
    },
  };
}

export function appRequestNotifPermission() {
  return {
    type: ACTION_TYPES.REQUEST_NOTIFICATION_PERMISSION,
  };
}

export function requestPendingReview() {
  return {
    type: ACTION_TYPES.REQUEST_PENDING_REVIEW,
  };
}
