import { apiCall, apiCallFormData, mockApiCall } from '..'
import { Api } from '../../config'
const report = (params = {}) => {
  const parameters = {
    contentID: params.contentID,
    message: params.message,
    reportType: params.reportType,
  }
  const request = {
    url: `${Api.baseURL}/report`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}
const problem = (formData) => {
  const url = `${Api.baseURL}/report-problem`
  return apiCallFormData(url, formData)
}
const feedback = (params = {}) => {
  const parameters = {
    feedback: params.feedback,
  }
  const request = {
    url: `${Api.baseURL}/feedback`,
    method: 'put',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}
export default {
  report,
  problem,
  feedback,
}
