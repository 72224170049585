import { apiCall, objToQueryString } from '..'
import { Api } from '../../config'

const forgetPassword = (email) => {
  const parameters = { email: email }

  const request = {
    url: `${Api.baseURL}/forget-password`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}
const verifyCode = ({ email, code }) => {
  const parameters = { code: code, email: email }

  const request = {
    url: `${Api.baseURL}/verify-code`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}
const resetPassword = ({ newPassword, code }) => {
  const parameters = { newPassword, code }

  const request = {
    url: `${Api.baseURL}/reset-password`,
    method: 'put',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}

export default {
  forgetPassword,
  verifyCode,
  resetPassword,
}
