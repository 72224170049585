import { apiCall, objToQueryString } from '..'
import { Api } from '../../config'
const create = (parameters = {}) => {
  const request = {
    url: `${Api.baseURL}/profileconnect`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}
const deleteAction = ({ fromProfileId, toProfileId }) => {
  const parameters = { fromProfileId, toProfileId }

  const request = {
    url: `${Api.baseURL}/profileconnect`,
    method: 'delete',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}
const getProfileConnects = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  }
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/profileconnect?` + objToQueryString(parameters),
    method: 'get',
  }
  return apiCall(request)
}
export default {
  create,
  deleteAction,
  getProfileConnects,
}
