import { apiCall, objToQueryString } from '..'
import { Api } from '../../config'

const list = (params) => {
  const request = {
    url: `${Api.baseURL}/notification-list?` + objToQueryString(params),
    method: 'get',
  }
  return apiCall(request)
}
const readAllNotifications = () => {
  const request = {
    url: `${Api.baseURL}/notification-read-all`,
    method: 'post',
  }
  return apiCall(request)
}
const getUnreadNotificationCount = () => {
  const request = {
    url: `${Api.baseURL}/notification-unread-count`,
    method: 'get',
  }
  return apiCall(request)
}
export default {
  list,
  readAllNotifications,
  getUnreadNotificationCount,
}
