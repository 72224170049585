import { apiCall, objToQueryString } from "..";
import { Api } from "../../config";

const getLocations = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 5,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url: `${Api.baseURL}/locations?` + objToQueryString(parameters),
    method: "get",
  };

  return apiCall(request);
};

export default {
  getLocations,
};
