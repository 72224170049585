import { Api } from "../../../../config";
import { Linking, Platform } from "react-native-web";
import * as ACTION_TYPES from "./ActionTypes";
// import SafariView from "react-native-safari-view";
import { jwtDecode } from "../../../../helpers";
import { getMe } from "./UserActions";
import storage from "redux-persist/lib/storage";

export function auth(credential) {
  return (dispatch, getState) => {
    console.log("auth - start");
    storage
      .getItem("auth")
      .then((authString) => (authString ? JSON.parse(authString) : {}))
      .then((auth) => {
        const hasAuth = auth && auth.accessToken;
        const authHasExpired = hasAuth
          ? jwtDecode(auth.accessToken).exp * 1000 < Date.now()
          : true;
        const body = credential;
        const request = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          url: `${Api.baseURL}/Auth`,
          method: "post",
          body: JSON.stringify(body),
        };

        // If user is logged in then use the authenticated route
        if (hasAuth && !authHasExpired) {
          request.headers.Authorization = `Bearer ${auth.accessToken}`;
          request.url = `${Api.baseURL}/AuthStrategy/connect`;
          dispatch({ type: ACTION_TYPES.SOCIAL_AUTH_CONNECT });
        } else {
          dispatch({ type: ACTION_TYPES.AUTH, credential: credential });
        }
        console.log("auth request", request);
        fetch(request.url, request).then((res) => {
          if (res.ok) {
            if (hasAuth && !authHasExpired) {
              //when connect social network auth
              dispatch({ type: ACTION_TYPES.SOCIAL_AUTH_CONNECT_SUCCESS });
              dispatch(getMe());
            } else {
              res.json().then((response) => {
                console.log("auth response", response);
                _persistAuth(response.data)
                  .then(() =>
                    dispatch({
                      type: ACTION_TYPES.AUTH_SUCCESS,
                      auth: response.data,
                    })
                  )
                  .then(() => {
                    dispatch(getMe());
                  })
                  .catch(console.error);
              });
            }
          } else {
            dispatch({ type: ACTION_TYPES.AUTH_FAILURE });
          }
        });
      });
  };
}

export function _persistAuth(auth) {
  return storage.setItem("auth", JSON.stringify(auth));
}

export function setIsNavigateToMain(flag) {
  return {
    type: ACTION_TYPES.SET_NAVIGATE_TO_MAIN,
    isNavigateToMain: flag,
  };
}
export function initializeMe(payload) {
  return {
    type: ACTION_TYPES.INITIALIZE_ME,
    request: {
      url: `${Api.baseURL}/Me/Initialize`,
      method: "post",
      body: JSON.stringify(payload),
    },
  };
}

export function emailVerificationCode(payload) {
  return (dispatch, getState) => {
    dispatch({ type: ACTION_TYPES.EMAIL_VERIFY });
    const body = payload;
    const request = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      url: `${Api.baseURL}/email-verification-code`,
      method: "post",
      body: JSON.stringify(body),
    };
    fetch(request.url, request).then((res) => {
      if (res.ok) {
        res.json().then((response) => {
          _persistAuth(response.data)
            .then(() =>
              dispatch({
                type: ACTION_TYPES.EMAIL_VERIFY_SUCCESS,
                auth: response.data,
              })
            )
            .catch(console.error);
        });
      } else {
        res
          .json()
          .then((response) => {
            dispatch({
              type: ACTION_TYPES.EMAIL_VERIFY_FAILURE,
              error: response.message,
            });
          })
          .catch(() => {
            console.log("catch error");
            dispatch({ type: ACTION_TYPES.EMAIL_VERIFY_FAILURE });
          });
      }
    });
  };
}

export function register(payload) {
  return (dispatch, getState) => {
    dispatch({ type: ACTION_TYPES.REGISTER });
    const body = payload;
    const request = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      url: `${Api.baseURL}/register`,
      method: "post",
      body: JSON.stringify(body),
    };
    fetch(request.url, request).then((res) => {
      if (res.ok) {
        res.json().then((response) => {
          _persistAuth(response.data)
            .then(() =>
              dispatch({
                type: ACTION_TYPES.REGISTER_SUCCESS,
                auth: response.data,
              })
            )
            .then(() => {
              dispatch(getMe());
            })
            .catch(console.error);
        });
      } else {
        res
          .json()
          .then((response) => {
            dispatch({
              type: ACTION_TYPES.REGISTER_FAILURE,
              error: response.message,
            });
          })
          .catch(() => {
            console.log("catch error");
            dispatch({ type: ACTION_TYPES.REGISTER_FAILURE });
          });
      }
    });
  };
}

export function visitWelcome() {
  return {
    type: ACTION_TYPES.VISIT_WELCOME,
  };
}

export function logout() {
  return (dispatch, getState) => {
    storage.removeItem("auth").then((result) => {
      dispatch({ type: ACTION_TYPES.LOGOUT });
    });
  };
}

//currently just removed from auth strategies connected with user,
//when user is not native user which means social media logined user like facebook, then check remaining auth strategy and return logout
export function destroyAuthStrategy(authStrategy, nativeUser) {
  let hasConnectedStrategy;
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const requestDisconnectAuth = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth
            ? `Bearer ${getState().auth.accessToken}`
            : "",
        },
        url: `${Api.baseURL}/AuthStrategy/disconnect`,
        method: "post",
        body: JSON.stringify({
          authStrategyId: authStrategy.id,
        }),
      };
      fetch(requestDisconnectAuth.url, requestDisconnectAuth)
        .then(() => {
          dispatch({ type: "AUTH_STRATEGY_DESTROY_SUCCESS", authStrategy });

          const requestMe = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: getState().auth
                ? `Bearer ${getState().auth.accessToken}`
                : "",
            },
            url: `${Api.baseURL}/profile`,
            method: "get",
          };
          dispatch(getMe());
        })
        .catch((err) => {
          reject(err);
        });
    });
}

export function connectApple(redirect, appleSettings) {
  return (dispatch, getState) => {
    const url = `https://appleid.apple.com/auth/authorize?response_type=${encodeURIComponent(
      "code id_token"
    )}&response_mode=form_post&scope=${encodeURIComponent(
      "name email"
    )}&client_id=${appleSettings.appId}&redirect_uri=${redirect}`;

    console.log("connect apple", url);
    // SafariView.isAvailable()
    //   .then(() => {
    //     SafariView.show({ url, fromBottom: true });
    //     //SafariView.addEventListener('onShow', () => dispatch(setStatusBarStyle('default')));
    //     //SafariView.addEventListener('onDismiss', () => dispatch(setStatusBarStyle('light-content')));
    //   })
    //   .catch(() => {
    //     Linking.openURL(url);
    //   });
  };
}

export function connectFacebook(redirect, facebookSettings) {
  return (dispatch, getState) => {
    const url = `https://www.facebook.com/dialog/oauth?scope=email&client_id=${facebookSettings.appId}&redirect_uri=${redirect}`;
    // SafariView.isAvailable()
    //   .then(() => {
    //     SafariView.show({ url, fromBottom: true });
    //     //SafariView.addEventListener('onShow', () => dispatch(setStatusBarStyle('default')));
    //     //SafariView.addEventListener('onDismiss', () => dispatch(setStatusBarStyle('light-content')));
    //   })
    //   .catch(() => {
    //     Linking.openURL(url);
    //   });
  };
}

export function connectGoogle(redirect, googleSettings, state) {
  return (dispatch, getState) => {
    if (googleSettings) {
      const url = `https://accounts.google.com/o/oauth2/auth?client_id=${googleSettings.clientId}&scope=openid+email+profile&response_type=code&redirect_uri=${redirect}&state=${state}&access_type=offline`;

      // SafariView.isAvailable()
      //   .then(() => {
      //     SafariView.show({ url, fromBottom: true });
      //     //SafariView.addEventListener('onShow', () => dispatch(setStatusBarStyle('default')));
      //     //SafariView.addEventListener('onDismiss', () => dispatch(setStatusBarStyle('light-content')));
      //   })
      //   .catch((error) => {
      //     Linking.openURL(url);
      //   });
    }

    return false;
  };
}
export function connectSpotify(redirect, spotifySettings) {
  return (dispatch, getState) => {
    const scopes =
      "streaming user-read-private user-top-read user-read-email playlist-modify-public user-follow-read";
    const url =
      "https://accounts.spotify.com/authorize" +
      `?client_id=${spotifySettings.clientId}` +
      "&response_type=code" +
      `&scope=${encodeURIComponent(scopes)}` +
      `&redirect_uri=${redirect}`;

    // SafariView.isAvailable()
    //   .then(() => {
    //     SafariView.show({ url, fromBottom: true });
    //     /*SafariView.addEventListener('onShow', () =>
    //     dispatch(setStatusBarStyle('default'))
    //   )
    //   SafariView.addEventListener('onDismiss', () =>
    //     dispatch(setStatusBarStyle('light-content'))
    //   )*/
    //   })
    //   .catch(() => Linking.openURL(url));
  };
}
export function getAuth(payload) {
  return {
    type: ACTION_TYPES.GET_AUTH,
    payload,
  };
}

export function forgotPassword(payload) {
  return {
    type: ACTION_TYPES.FORGOT_PASSWORD,
    request: {
      url: `${Api.baseURL}/ForgotPassword`,
      method: "post",
      body: JSON.stringify(payload),
    },
  };
}
